import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className="home page--home">
      <Link to="/alphabet">Alphabet</Link>
      <Link to="/words">Spell The Word</Link>
    </div>
  )
}

export default Home;
