import React, { useState } from 'react';
import alphabetInfo from '../alphabetInfo';
import AlphabetCard from './AlphabetCard';
import './styles.css';

const Alphabet = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleCardClick = (letter) => {
    setExpandedCard(letter);
  };

  const closeExpandedCard = () => {
    setExpandedCard(null);
  };

  const cardStyle = image => {
    return {
      backgroundImage: `url(${image})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right bottom",
      backgroundSize: "50% auto"
    }
  }

  return (
    <div className={`page page--alphabet`}>
      {
        alphabetInfo.map((item => (
          <div key={item.letter} onClick={() => handleCardClick(item)}>
            <AlphabetCard
              key={item.letter}
              handleClose={closeExpandedCard}
              cardStyle={cardStyle(item.image)}
              letter={item.letter}
              sound={item.sound}
            />
          </div>
        )))
      }
      {expandedCard && (
        <div className="overlay">
          <AlphabetCard
            handleClose={closeExpandedCard}
            expanded={true}
            expandedCard={expandedCard}
            cardStyle={cardStyle(expandedCard.image)}
          />
        </div>
      )}
    </div>
  );
};

export default Alphabet;
