import {
  imageApple,
  imagebee,
  imagecar,
  imagedog,
  imageegg,
  imagefish,
  imageguitar,
  imagehat,
  imageigloo,
  imagejar,
  imagekite,
  imagelion,
  imagemouse,
  imagenose,
  imageoctopus,
  imagepencil,
  imagequeen,
  imagerobot,
  imagesocks,
  imagetshirt,
  imageumbrella,
  imagevolcano,
  imagewhale,
  imagexray,
  imageyoyo,
  imagezebra,
} from "./images/animals";

import {
  soundsAlphabet_A,
  soundsAlphabet_B,
  soundsAlphabet_C,
  soundsAlphabet_D,
  soundsAlphabet_E,
  soundsAlphabet_F,
  soundsAlphabet_G,
  soundsAlphabet_H,
  soundsAlphabet_I,
  soundsAlphabet_J,
  soundsAlphabet_K,
  soundsAlphabet_L,
  soundsAlphabet_M,
  soundsAlphabet_N,
  soundsAlphabet_O,
  soundsAlphabet_P,
  soundsAlphabet_Q,
  soundsAlphabet_R,
  soundsAlphabet_S,
  soundsAlphabet_T,
  soundsAlphabet_U,
  soundsAlphabet_V,
  soundsAlphabet_W,
  soundsAlphabet_X,
  soundsAlphabet_Y,
  soundsAlphabet_Z,
} from "./sounds";

export const cvcWords = ['cat', 'bed', 'big', 'fox', 'hut', 'map', 'pin', 'top', 'rug', 'fan', 'lip', 'mud', 'pen', 'dog', 'hop', 'jet', 'tub', 'rat', 'nut', 'wet'];

const alphabetInfo = [
  {
    letter: 'a',
    word: "apple",
    image: imageApple,
    sound: soundsAlphabet_A
  },
  {
    letter: 'b',
    word: "bee",
    image: imagebee,
    sound: soundsAlphabet_B
  },
  {
    letter: 'c',
    word: "car",
    image: imagecar,
    sound: soundsAlphabet_C
  },
  {
    letter: 'd',
    word: "dog",
    image: imagedog,
    sound: soundsAlphabet_D
  },
  {
    letter: 'e',
    word: "egg",
    image: imageegg,
    sound: soundsAlphabet_E
  },
  {
    letter: 'f',
    word: "fish",
    image: imagefish,
    sound: soundsAlphabet_F
  },
  {
    letter: 'g',
    word: "guitar",
    image: imageguitar,
    sound: soundsAlphabet_G
  },
  {
    letter: 'h',
    word: "hat",
    image: imagehat,
    sound: soundsAlphabet_H
  },
  {
    letter: 'i',
    word: "igloo",
    image: imageigloo,
    sound: soundsAlphabet_I
  },
  {
    letter: 'j',
    word: "jar",
    image: imagejar,
    sound: soundsAlphabet_J
  },
  {
    letter: 'k',
    word: "kite",
    image: imagekite,
    sound: soundsAlphabet_K
  },
  {
    letter: 'l',
    word: "lion",
    image: imagelion,
    sound: soundsAlphabet_L
  },
  {
    letter: 'm',
    word: "mouse",
    image: imagemouse,
    sound: soundsAlphabet_M
  },
  {
    letter: 'n',
    word: "nose",
    image: imagenose,
    sound: soundsAlphabet_N
  },
  {
    letter: 'o',
    word: "octopus",
    image: imageoctopus,
    sound: soundsAlphabet_O
  },
  {
    letter: 'p',
    word: "pencil",
    image: imagepencil,
    sound: soundsAlphabet_P
  },
  {
    letter: 'q',
    word: "queen",
    image: imagequeen,
    sound: soundsAlphabet_Q
  },
  {
    letter: 'r',
    word: "robot",
    image: imagerobot,
    sound: soundsAlphabet_R
  },
  {
    letter: 's',
    word: "socks",
    image: imagesocks,
    sound: soundsAlphabet_S
  },
  {
    letter: 't',
    word: "tshirt",
    image: imagetshirt,
    sound: soundsAlphabet_T
  },
  {
    letter: 'u',
    word: "umbrella",
    image: imageumbrella,
    sound: soundsAlphabet_U
  },
  {
    letter: 'v',
    word: "volcano",
    image: imagevolcano,
    sound: soundsAlphabet_V
  },
  {
    letter: 'w',
    word: "whale",
    image: imagewhale,
    sound: soundsAlphabet_W
  },
  {
    letter: 'x',
    word: "xray",
    image: imagexray,
    sound: soundsAlphabet_X
  },
  {
    letter: 'y',
    word: "yoyo",
    image: imageyoyo,
    sound: soundsAlphabet_Y
  },
  {
    letter: 'z',
    word: "zebra",
    image: imagezebra,
    sound: soundsAlphabet_Z
  },
]

export default alphabetInfo;
