import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import Alphabet from './components/Alphabet';
import Navigation from './components/Navigation';
import LearnToWrite from './components/LearnToWrite';
import alphabetInfo from './alphabetInfo';
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/alphabet" element={<Alphabet data={alphabetInfo} />} />
          <Route path="/words" element={<LearnToWrite />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
