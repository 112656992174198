import React from 'react'

const AlphabetCard = ({
  handleClose,
  cardStyle,
  letter,
  sound,
  expanded,
  expandedCard
}) => {
  const playAudio = (audio) => {
    const audioElement = new Audio(audio);
    audioElement.play();
  };

  return (
    <div
      className={`alphabet-card ${expanded ? 'expanded' : ''}`}
      style={cardStyle}
    >
      <div className="uppercase">{letter || expandedCard?.letter}</div>
      <div className="lowercase">{letter || expandedCard?.letter}</div>
      {expanded && (
        <>
          <span
            className="speaker-icon"
            onClick={() => playAudio(sound || expandedCard?.sound)}
          >
            🎵
          </span>
        </>
      )}
      {expanded && <span className="alphabet-card__close" onClick={handleClose}>X</span>}
    </div>
  )
}

export default AlphabetCard