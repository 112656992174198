import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import alphabetInfo from '../alphabetInfo';

const SpellTheWord = () => {
  const [currentWord, setCurrentWord] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [alphabet, setAlphabet] = useState([]);
  const [clickedLetters, setClickedLetters] = useState([]);
  const [completed, setCompleted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [finalLetter, setFinalLetter] = useState(null);

  const randomIndex = Math.floor(Math.random() * alphabetInfo.length);

  const navigate = useNavigate();

  const getRandomData = () => {
    const randomWord = alphabetInfo[randomIndex].word;
    const randomImage = alphabetInfo[randomIndex].image;

    return {
      word: randomWord,
      image: randomImage
    }
  }

  function handleLetterClick(letter) {
    if (completed) return; // Game is finished, do nothing

    console.log(letter);

    const currentLetterIndex = clickedLetters.length;

    if (currentWord[currentLetterIndex] === letter.toLowerCase()) {
      console.log('correct letter')
      // Correct letter clicked
      setClickedLetters([...clickedLetters, letter]);

      if (currentLetterIndex === currentWord.length - 1) {
        setFinalLetter(letter);
        setCompleted(true);
        setShowModal(true);
      }
    } else {
      // Incorrect letter clicked
      // You can handle incorrect letter clicks if needed
    }
  }

  const createAlphabet = () => {
    return Array.from({ length: 26 }, (_, index) => String.fromCharCode(65 + index));
  }

  const handlePlayAgain = () => {
    setClickedLetters([]);
    setCompleted(false);
    setFinalLetter(null);
    setCurrentWord(getRandomData().word)
    setCurrentImage(getRandomData().image);
    setShowModal(false);
  };

  const handleNoClick = () => {
    setShowModal(false);
    navigate('/');
  };

  useEffect(() => {
    setAlphabet(createAlphabet);
    console.log(getRandomData().word)
    console.log(getRandomData().image)
    setCurrentWord(getRandomData().word)
    setCurrentImage(getRandomData().image);
  }, []);

  return (
    <div className="page page--words">
      <div className="words--current-image">
        <img src={currentImage} alt="Current" />
      </div>
      <div className="alphabet">
        {alphabet.map((letter) => (
          <button className="button" key={letter} onClick={() => handleLetterClick(letter)}>
            {letter}
          </button>
        ))}
      </div>
      <div className="user-correct-letters">
        {clickedLetters.map((letter, index) => (
          <span key={index}>
            {letter}
          </span>
        ))}
      </div>
      {completed && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Great job!</p>
            <div className="modal-content__actions">
              <button className="button" onClick={handleNoClick}>Go Home</button>
              <button className="button" onClick={handlePlayAgain}>Play Again</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpellTheWord;
